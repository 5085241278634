import { generateIframeUrlByHostName } from '@app/@shared/utils/shared.utils';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-meili-widget',
  templateUrl: './meili-widget.component.html',
  styleUrls: ['./meili-widget.component.scss', '../../../../theme/overlay-loader.scss'],
})
export class MeiliWidgetComponent implements OnInit {
  b2b_Token: any = '';
  iframeUrl: any = null;
  showLoader: boolean = true;
  constructor(private _sanitizationService: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    this.iframeUrl = this.getIframeUrl();
    window.addEventListener('message', (event) => {
      // this is configured in b2c app component on router events change
      if (event.data && event.data.type === 'B2BIframeloaded') {
        this.showLoader = false;
      }
    });
  }
  getIframeUrl() {
    return this._sanitizationService.bypassSecurityTrustResourceUrl(this.iframeUrlByHostName());
  }

  iframeUrlByHostName() {
    let iframe_Link = generateIframeUrlByHostName();
    let bearerToken: any = sessionStorage.getItem('accessTokenData')
      ? JSON.parse(sessionStorage.getItem('accessTokenData')).access
      : null;
    let B2BUser = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    this.b2b_Token = bearerToken;
    return `${iframe_Link}/B2B-meili-widget?b2b_Token=${this.b2b_Token}&organization=${B2BUser?.organization_id}&userId=${B2BUser?.user_id}`;
  }
  navigateToDashboard() {
    sessionStorage.removeItem('iframeUrl');
    this.router.navigate(['/agent']);
  }
}
