<div class="navList">
  <ul class="pl-0">
    <li (click)="selectedTab('Dashboard')" [ngClass]="{ active: isActive('Dashboard') }">
      <span>
        <img src="../../../assets/icons/dashboard_Icons/dashboard_icn.svg" alt="dashboard" class="tabLogo"
      /></span>
      <span>Dashboard</span>
    </li>
    <li
      *ngIf="hasPermissionForProfileAgent()"
      (click)="selectedTab('Profile')"
      [ngClass]="{ active: isActive('Profile') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/profile_icn.svg" alt="profile" class="tabLogo" /></span>
      <span>Profile</span>
    </li>

    <li
      *ngIf="hasPermissionToAddMarkup()"
      (click)="selectedTab('AddMarkup')"
      [ngClass]="{ active: isActive('AddMarkup') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/add_markup.svg" alt="add_markup" class="tabLogo" /></span>
      <span>Add Markup</span>
    </li>
    <li
      *ngIf="hasPermissionToAccessDashboardAPIs()"
      (click)="selectedTab('Bookings')"
      [ngClass]="{ active: isActive('Bookings') }"
    >
      <span
        ><img src="../../../assets/icons/dashboard_Icons/sidenav-bookings.svg" alt="sidenav-bookings" class="tabLogo"
      /></span>
      <span>Bookings</span>
    </li>
    <li
      *ngIf="hasPermissionToAccessDashboardAPIs()"
      (click)="selectedTab('Payments')"
      [ngClass]="{ active: isActive('Payments') }"
    >
      <span
        ><img src="../../../assets/icons/dashboard_Icons/sidenav-payments.svg" alt="sidenav-payments" class="tabLogo"
      /></span>
      <span>Payments</span>
    </li>
    <li
      *ngIf="hasPermissionToAccessDashboardAPIs()"
      (click)="selectedTab('Travellers')"
      [ngClass]="{ active: isActive('Travellers') }"
    >
      <span
        ><img
          src="../../../assets/icons/dashboard_Icons/sidenav-travellers.svg"
          alt="sidenav-travellers"
          class="tabLogo"
          style="width: 14px !important"
      /></span>
      <span>Travellers</span>
    </li>
    <li
      *ngIf="hasPermissionForWalletAgent()"
      (click)="selectedTab('MyWallet')"
      [ngClass]="{ active: isActive('MyWallet') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/wallet.svg" alt="wallet" class="tabLogo" /></span>
      <span>My Wallet</span>
    </li>
    <li
      *ngIf="hasPermissionForUserCreation()"
      [routerLink]="['./create-user']"
      (click)="selectedTab('CreateUser')"
      [ngClass]="{ active: isActive('CreateUser') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/create_user.svg" alt="create_user" class="tabLogo" /></span>
      <span>Create User</span>
    </li>
    <li
      *ngIf="hasPermissionForStaffList()"
      [routerLink]="['./company-staff']"
      (click)="selectedTab('StaffList')"
      [ngClass]="{ active: isActive('StaffList') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/users_list.svg" alt="users_list" class="tabLogo" /></span>
      <span>Staff List</span>
    </li>
    <li
      *ngIf="hasPermissionForAuditLog()"
      [routerLink]="['./audit-logs']"
      (click)="selectedTab('AuditLogs')"
      [ngClass]="{ active: isActive('AuditLogs') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/audit_logs.svg" alt="audit_logs" class="tabLogo" /></span>
      <span>Audit Logs</span>
    </li>
    <li (click)="logout()">
      <span><img src="../../../assets/icons/dashboard_Icons/logout.svg" alt="logout" class="tabLogo" /></span>
      <span>Logout</span>
    </li>
    <li *ngIf="hasPermissionForReservedBookings() || hasPermissionForCardLink()">
      <span>OTHERS</span>
    </li>
    <li
      *ngIf="hasPermissionForReservedBookings()"
      [routerLink]="['./reserved-bookings']"
      (click)="selectedTab('ReservedBookings')"
      [ngClass]="{ active: isActive('ReservedBookings') }"
    >
      <span
        ><img src="../../../assets/icons/dashboard_Icons/bookings-icn.svg" alt="bookings-icn" class="tabLogo"
      /></span>
      <span translate>Book On Hold</span>
    </li>
    <li
      *ngIf="hasPermissionForCardLink()"
      [routerLink]="['./generate-paymentlink']"
      (click)="selectedTab('CardLink')"
      [ngClass]="{ active: isActive('CardLink') }"
    >
      <span><img src="../../../assets/icons/dashboard_Icons/card-link.svg" alt="card-link" class="tabLogo" /></span>
      <span>Card Link</span>
    </li>
    <li
      *ngIf="user?.organization_id == 'TS_FS'"
      [routerLink]="['./documents']"
      (click)="selectedTab('documents')"
      [ngClass]="{ active: isActive('documents') }"
    >
      <span
        ><img src="../../../assets/icons/dashboard_Icons/documents-menu.svg" alt="card-link" class="tabLogo"
      /></span>
      <span>Documents</span>
    </li>
    <li *ngIf="user?.organization_id == 'TS_FS'">
      <span class="text-uppercase" translate>Contact us</span>
      <div translate class="my-2">0861333392</div>
      <div translate>info@flightsiteagent.co.za</div>
    </li>
    <li *ngIf="user?.organization_id == 'TS_CT'">
      <div>
        <div class="text-uppercase py-1" translate>Tools</div>
        <div class="additionalList" (click)="selectedTab('ourweb')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Our Web</span>
          <span
            ><img
              src="../../../assets/icons/dashboard_Icons/newTab.svg"
              alt="newTab"
              class="tabLinkImg"
              class="tabLinkImg"
          /></span>
        </div>
        <div
          class="additionalList d-none"
          (click)="
            openLink(
              'https://clubtravel.atlassian.net/servicedesk/customer/portal/22/user/login?destination=portal%2F22'
            )
          "
        >
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Help & Support </span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div class="additionalList" (click)="openLink('https://www.pnrconverter.com/')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>PNR Converter</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div class="additionalList" (click)="openLink('https://secure.eglobalfares.com/')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>EGF</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div class="additionalList" (click)="openLink('https://eu.verteil.com/login')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>NDC Verteil</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div class="additionalList" (click)="openLink('https://thomalex.resvoyage.com/Account/LogOn')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Thomalex</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
      </div>
      <div class="">
        <div class="text-uppercase py-1" translate>Ancillaries</div>
        <div class="additionalList" (click)="openLink('https://www.hepstar.com/')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Hepstar</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div
          class="additionalList"
          (click)="openLink('https://travelinsurance.brytesa.com/direct?organisationcode=club&nisorganisationcode=')"
        >
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Bryte</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div
          class="additionalList"
          (click)="
            openLink(
              'https://docs.google.com/spreadsheets/d/1MdpV74uB-GlQxu4tOmWjY8Qs1rtCiMbJgDr_f1rfIHc/edit?usp=sharing'
            )
          "
        >
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>FAQ</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
      </div>
      <div class="d-none">
        <div
          class="text-uppercase additionalList py-1"
          (click)="openLink('https://sites.google.com/clubtravel.co.za/club-travel-ourweb/news')"
        >
          <span>Our Web</span>
          <span
            ><img
              src="../../../assets/icons/dashboard_Icons/newTab.svg"
              alt="newTab"
              class="tabLinkImg"
              class="tabLinkImg"
          /></span>
        </div>
        <div class="additionalList d-none" (click)="openLink('https://aj.myclubtravel.co.za/agent/intranet/franchise')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Franchise</span>
          <span
            ><img
              src="../../../assets/icons/dashboard_Icons/newTab.svg"
              alt="newTab"
              class="tabLinkImg"
              class="tabLinkImg"
          /></span>
        </div>
        <div class="additionalList d-none" (click)="openLink('https://aj.myclubtravel.co.za/agent/intranet/ticketing')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Ticketing & BSP</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div class="additionalList d-none" (click)="openLink('https://aj.myclubtravel.co.za/agent/intranet/deals')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Summary of Deals</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
        <div class="additionalList d-none" (click)="openLink('https://aj.myclubtravel.co.za/agent/intranet/contact')">
          <span><img src="../../../assets/icons/dashboard_Icons/newLink.svg" alt="newLink" class="tabLogo" /></span>
          <span>Contact</span>
          <span><img src="../../../assets/icons/dashboard_Icons/newTab.svg" alt="newTab" class="tabLinkImg" /></span>
        </div>
      </div>
    </li>
  </ul>
</div>
