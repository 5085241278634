import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { SessionUtils } from '@app/general/utils/session-utils';
import { UtilityService } from '@app/@core/services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { ApiService } from '@app/general/services/api/api.service';
import { generateIframeUrlByHostName } from '@app/@shared/utils/shared.utils';
@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss', '../../../../theme/overlay-loader.scss'],
})
export class IframeComponent implements OnInit {
  @ViewChild('iframeRef', { static: false }) iframe: ElementRef<HTMLIFrameElement>;
  iframeUrl: any;
  b2bAuthToken: boolean = false;
  isShowIframeContainer: boolean = true;
  b2b_Token: any = '';
  b2b_CorrelationId: string = '';
  uuid: string = null;
  invid: string = null;
  tccReference: any = null;
  iframeLoaded: boolean = false;

  constructor(
    private sessionStorageService: SessionStorageService,
    private utilityService: UtilityService,
    private router: Router,
    private _sanitizationService: DomSanitizer,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.location.subscribe((event) => {
      // This code will be executed when the back or forward button is clicked
      this.handleBrowserBack();
    });
    this.activatedRoute.queryParams.subscribe((data: any) => {
      if (data?.uuid && data?.invid && data?.tccReference) {
        this.uuid = data?.uuid;
        this.invid = data.invid;
        this.tccReference = data.tccReference;
      }
    });
  }

  ngOnInit(): void {
    window.addEventListener(
      'message',
      (event) => {
        // this is configured in b2c app component on router events change
        if (event.data && event.data.type === 'B2BIframeloaded') {
          this.iframeLoaded = true;
        } else if (event.data && event.data.type === 'iframeUrlChanged') {
          const newUrl = event.data.url;
          sessionStorage.setItem('iframeUrl', newUrl);

          /* here we are disable back to dashboard section in SRP & flight details & payment sections
         this.isShowIframeContainer = true;
        */
          if (
            !this.apiService.isFlightsiteDomain() &&
            (newUrl.includes('/flights/results') ||
              newUrl.includes('booking/flight-details') ||
              newUrl.includes('booking/products') ||
              (newUrl.includes('/payments') && !newUrl.includes('/payments/bookingConfirm')))
          ) {
            this.isShowIframeContainer = false;
          } else {
            this.isShowIframeContainer = true;
          }
        } else if (event?.data?.type === 'navigateToBackPage') {
          this.router.navigate(['/agent']);
        } else if (event.data && event.data.type === 'navigateToReservedList') {
          sessionStorage.removeItem('iframeUrl');
          this.router.navigate(['/agent/reserved-bookings']);
        } else if (event?.data?.type === 'updateCorrelationId') {
          const correlationId = event.data.correlationId;
          const queryParams = { correlation_Id: correlationId };
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge', // This keeps existing query params and merges the new ones
          });
        }
      },
      false
    );

    if (sessionStorage.getItem('iframeUrl')) {
      this.iframeUrl = this._sanitizationService.bypassSecurityTrustResourceUrl(sessionStorage.getItem('iframeUrl'));
    } else {
      this.iframeUrl = this.getIframeUrl();
    }
    setTimeout(() => {
      this.sendMessageToIframe();
    }, 4000);
  }

  getIframeUrl() {
    return this._sanitizationService.bypassSecurityTrustResourceUrl(this.iframeUrlByHostName());
  }

  iframeUrlByHostName() {
    let iframe_Link = generateIframeUrlByHostName();
    let bearerToken: any = sessionStorage.getItem('accessTokenData')
      ? JSON.parse(sessionStorage.getItem('accessTokenData')).access
      : null;
    let B2BUser = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    this.b2b_Token = bearerToken;
    this.b2b_CorrelationId = this.sessionStorageService.retrieve(SessionUtils.CORRELATION_ID);
    return this.uuid && this.invid && this.tccReference
      ? `${iframe_Link}/find-itinerary?b2b_Token=${this.b2b_Token}&organization=${B2BUser?.organization_id}&uuid=${this.uuid}&invid=${this.invid}&tccReference=${this.tccReference}`
      : `${iframe_Link}?b2b_Token=${this.b2b_Token}&organization=${B2BUser?.organization_id}`;
  }

  sendMessageToIframe() {
    let bearerToken = sessionStorage.getItem('accessTokenData')
      ? JSON.parse(sessionStorage.getItem('accessTokenData')).access
      : null;
    if (bearerToken) {
      this.b2b_Token = bearerToken;
      this.b2b_CorrelationId = this.sessionStorageService.retrieve(SessionUtils.CORRELATION_ID);
      this.b2bAuthToken = true;
    }
  }

  navigateToDashboard() {
    sessionStorage.removeItem('iframeUrl');
    this.router.navigate(['/agent']);
  }
  navigateToFlights() {
    (document.getElementById('IframeRef') as HTMLInputElement).src = this.iframeUrl;
    this.router.navigate(['/bookingFlights']);
  }

  // Method to be called when the browser back button is clicked
  handleBrowserBack() {
    sessionStorage.removeItem('iframeUrl');
  }
}
