// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: false,
  serverUrl: '/api',
  firebase: {
    // apiKey: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    // authDomain: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    // databaseURL: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    // projectId: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    // storageBucket: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    // messagingSenderId: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    // appId: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',

    // apiKey: "AIzaSyCffhiUulPD87Nlkr9OLJD3CvtOLtxQqas",
    // authDomain: "jscore-sandbox-141b5.firebaseapp.com",
    // databaseURL: "https://jscore-sandbox-141b5.firebaseio.com",
    // projectId: "jscore-sandbox-141b5",
    // storageBucket: "jscore-sandbox-141b5.appspot.com",
    // messagingSenderId: "280127633210",
    // appId: '906313145378-qqkdhgf2k8b1iv0fl7aqqvqipduf2772.apps.googleusercontent.com'

    apiKey: 'AIzaSyDuPjt4X1UWHXW4IiQ5fOJFLF69GukF_e0',
    authDomain: 'userauthentication-bd379.firebaseapp.com',
    projectId: 'userauthentication-bd379',
    storageBucket: 'userauthentication-bd379.appspot.com',
    messagingSenderId: '906313145378',
    appId: '1:906313145378:web:c04005cff7064f0cfb7e7b',
    measurementId: 'G-4CD741RQYK',
  },
  defaultLanguage: 'en-NA',
  supportedLanguages: [
    'en-GO',
    'en-NA',
    'en-ZA',
    'en-BH',
    'en-BW',
    'en-EG',
    'en-KE',
    'en-KW',
    'en-MA',
    'en-NG',
    'en-OM',
    'en-QA',
    'en-SA',
    'en-TZ',
    'en-TR',
    'en-AE',
    'en-ZW',
    'en-FS',
    'en-CT',
  ],
  appVersion: require('../../package.json').version,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
